class PageContentType {
    type: string = "project";
    static PROJECT: PageContentType = new PageContentType("project");
    static TEAM: PageContentType = new PageContentType("team");
    static UNCLAIMED_TEAM: PageContentType = new PageContentType("unclaimed_team");
    static DELIVERY: PageContentType = new PageContentType("delivery");
    static CHECKLIST: PageContentType = new PageContentType("checklist");

    constructor(type: string) {
        this.type = type;
    }
}

export default PageContentType;
